import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme, { load?: number; financ?: boolean }>(
  (theme) => ({
    container: {
      background: (financ) =>
        financ ? "rgba(255,  255, 255,0.9)" : "rgba(244, 243, 243, 0.95)",
      borderRadius: 20,
      width: 260,
      padding: "12px 12px",
      boxShadow: "4.7px 4.7px 17.6px 5.9px rgba(0, 0, 0, 0.25)",
      cursor: "pointer",
      fontFamily: "Montserrat",
      backdropFilter: "blur(6px)",
      [theme.breakpoints.down(1279)]: {
        width: (financ) => (financ ? 260 : 160),
        height: "7.5rem",
      },
      [theme.breakpoints.down(560)]: {
        width: "160px!important",
      },
      "& .title": {
        color: theme.palette.text.primary,
        fontSize: "0.875rem",
        display: "block",
        fontFamily: theme.typography.fontFamily,
        padding: (financ) => (financ ? "10px 0 10px" : "10px 0 5px"),
        [theme.breakpoints.down(1279)]: {
          fontSize: 14,
          marginTop: "0rem",
        },
      },
      "& .time": {
        fontSize: 14,
        fontFamily: "Montserrat",
        fontWeight: 300,
        marginTop: "1rem",
      },
      "& span": {
        display: "block",
      },
      "& .line": {
        "& svg": {
          width: "100%",
          borderRadius: 20,
          [theme.breakpoints.down(1279)]: {
            marginTop: "0rem",
          },
        },
      },
    },
    img: {
      background: "#0a5669",
      borderRadius: "10px 10px 10px 0",
      height: (financ) => (financ ? 120 : 80),
      display: "flex",
      alignItems: "center",
      "& img": {
        width: (financ) => (financ ? 25 : 18),
        margin: "0 auto",
      },
    },
  })
);
