import React, { useState, useCallback, useEffect } from 'react';
import { useStyles } from './RokipediaLayout.style';
import RokipediaToolsMenu from './RokipediaTools/RokipediaToolsMenu';
import { useMediaQuery } from '@material-ui/core';
import { getUserMissions } from '@apollo';
import Higher from '@components/Incentives/Higher/Higher';
import {
  ALLIANCES_LIGHT_BLUE,
  LABEL_LIGHT_BLUE,
  ROKS_LIGHT_BLUE,
  TOOLS_LIGHT_BLUE_HOLLOW,
} from 'images/AWS/rokipedia/index';
import { CloseRounded } from '@material-ui/icons';
import { ButtonPrimary } from '@components';
import HeaderFinancialPortal from '@components/FinancialPortal/Header/Header';

const RokipediaLayout = () => {
  const [isSelect, setSelect] = useState(0);

  useEffect(() => {
    const location = window.location.href.split('#')[1];
    location && location === 'tools'
      ? setSelect(1)
      : location === 'alliance' && setSelect(2);
  }, []);

  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1280px)');

  const { userMissions } = getUserMissions();

  const missionsByCategory = useCallback(() => {
    const missions = Object.values(userMissions).sort((a, b) => {
      return a.mission.id - b.mission.id;
    });
    return Object.entries(
      missions.reduce((acc, cur) => {
        acc[cur.mission.category] = [...(acc[cur.mission.category] || []), cur];
        return acc;
      }, [])
    );
  }, [userMissions]);

  const infoButtonHandler = () => {
    //OPEN POPUP
    setOpenInfoPopUp(!openInfoPopUp);
  };

  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  return (
    <>
      <HeaderFinancialPortal
        setSelect={setSelect}
        isSelect={isSelect}
        infoPopup={infoButtonHandler}
        which={'rokipedia'}
        background="#4619B3"
      >
        {isSelect === 0 && (
          <div className={classes.missionsData}>
            <Higher missionsByCategory={missionsByCategory()} />
          </div>
        )}
        {isSelect === 1 && (
          <div className={classes.missionsData}>
            <RokipediaToolsMenu />
          </div>
        )}
        {isSelect === 2 && (
          <div className={classes.missionsData}>ALIANZAS AQUI</div>
        )}

        <div
          className={
            openInfoPopUp ? classes.popUpContainer : classes.hiddenDisplay
          }
        >
          <div className={classes.outerBorder}>
            <div className={classes.popUp}>
              <div className={classes.itemRow}>
                <img src={LABEL_LIGHT_BLUE} alt="" />
                <h3 className={classes.rokipediaPopUpTitle}>Rokipedia</h3>
                <button onClick={infoButtonHandler}>
                  <CloseRounded htmlColor="#393e44" />
                </button>
              </div>
              <div className={classes.popUpVector}></div>

              <p className={classes.subtitle}>
                Aprende todo lo que necesitas saber sobre el mundo financiero:
              </p>

              <div className={classes.textButtonContainer}>
                <img src={ROKS_LIGHT_BLUE} alt="" className={classes.image} />
                <p className={classes.subtext}>
                  ¡Completa las{' '}
                  <strong className={classes.strongText}>misiones</strong> de
                  cada unidad y gana{' '}
                  <strong className={classes.strongText}>roks</strong> para{' '}
                  <strong>subir de nivel</strong>!
                </p>
              </div>
              <div className={classes.textButtonContainer}>
                <img
                  src={TOOLS_LIGHT_BLUE_HOLLOW}
                  alt=""
                  className={classes.image2}
                />
                <p className={classes.subtext}>
                  Encuentra las{' '}
                  <strong className={classes.strongText}>herramientas</strong>{' '}
                  que necesitas para mejorar tu situación financiera y manejar
                  tu plata de la mejor manera
                </p>
              </div>
              <div className={classes.textButtonContainer}>
                <img
                  src={ALLIANCES_LIGHT_BLUE}
                  alt=""
                  className={classes.image2}
                />
                <p className={classes.subtext}>
                  Descubre las increibles{' '}
                  <strong className={classes.strongText}>alianzas</strong> que
                  tiene Rokin para que accedas a{' '}
                  <strong className={classes.strongText}>descuentos</strong>{' '}
                  increíbles y sigas profundizando en tu aprendizaje
                </p>
              </div>

              <div className={classes.buttonGotItContainer}>
                <ButtonPrimary
                  className={classes.buttonGotIt}
                  onClick={() => {
                    infoButtonHandler();
                  }}
                  text="Entendido"
                  secondaryVersion
                  noDegraded
                />
              </div>
            </div>
          </div>
        </div>
      </HeaderFinancialPortal>
    </>
  );
};

export default RokipediaLayout;
