import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  mainContainer:{
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    gap: "3rem",
    padding: "1rem 3rem 14rem 3rem",
    [theme.breakpoints.down(1280)]: {
        width:"100%",
        flexDirection: "column",
        background: "#F4F3F3",
        borderRadius: "20px 20px 0 0",
        padding:"1.5rem 1.5rem 9rem 1.5rem"
    },
  },
  budgetAndRetirement: {},
  card: {
    background: "#F4F3F3",
    width: "23.688rem",
    height: "18.813rem",
    padding: "1.938rem 1.75rem 1.75rem 1.813rem",
    borderRadius: "20px",
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
  },
  cardSmall: {
    background: "#F4F3F3",
    width: "23.688rem",
    height: "8.875rem",
    margin: "0 0 1rem 0",
    padding: "1.938rem 1.75rem 1.75rem 1.813rem",
    borderRadius: "20px",
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
  },
  cardTitleButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  collapsableButton: {
    marginTop: "1.5rem",
    transform: "rotate(90deg)",
    transition: "0.3s",
  },
  collapsableButtonClosed: {
    marginTop: "1.5rem",
    transition: "0.3s",
  },
  cardTitle: {
    fontFamily: "Noto Sans,Montserrat",
    fontSize: "1rem",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#575757",
    [theme.breakpoints.down(1280)]: {
      margin: "2rem 0 0 0",
    },
  },
  toolButton: {
    width: "100%",
  },
  innerCard: {
    margin: "1rem 0 0 0",
    height: "3.75rem",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#ffffff",
    padding: "0 0 0 1rem",
  },
  innerCardDisabled: {
    opacity: 0.35,
    margin: "1rem 0 0 0",
    height: "3.75rem",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#ffffff",
    padding: "0 0 0 1rem",
  },
  innerCardTitle: {
    textAlign: "left",
    fontFamily: "Noto Sans,Montserrat",
    fontSize: "0.875rem",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.71,
    letterSpacing: "normal",
    color: "#393e44",
    marginLeft: "1rem",
  },
  financialToolsTitle: {
    fontFamily: "Montserrat",
    fontSize: "1.625rem",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.2,
    letterSpacing: "normal",
    color: "#393e44",
    marginLeft: "0rem",
    [theme.breakpoints.down(1280)]: {
      fontSize: 20,
    },
  },
  showDisplay: {},
  hiddenDisplay: {
    display: "none",
  },
}));
