import { LEVEL1, LEVEL2, LEVEL3, LEVEL4, LEVEL5 } from 'images/GCP';

export const LevelsInfo = {
    Level1: {
        id: 1,
        name: 'Trueque',
        image: LEVEL1,
    },
    Level2: {
        id: 2,
        name: 'Granos de café',
        image: LEVEL2,
    },
    Level3: {
        id: 3,
        name: 'Dracma',
        image: LEVEL3,
    },
    Level4: {
        id: 4,
        name: 'Monedas de cobre',
        image: LEVEL4,
    },
    Level5: {
        id: 5,
        name: 'Monedas de oro',
        image: LEVEL5,
    },
};