import React, { useState, useCallback, SetStateAction } from "react";
import { useStyles } from "./RokipediaToolsMenu.style";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { PageProps } from "gatsby";
import { LayoutOnBoarding, SEO, CategoryView } from "@components";
import { useAuth } from "@hooks";
import { useMediaQuery } from "@material-ui/core";
import { LayoutMobileInvestments } from "@components/Investments/Layout";
import { getUserMissions } from "@apollo";
import { MissionsInfo } from "@data";
import { KeyboardArrowRightRounded } from "@material-ui/icons";
import Higher from "@components/Incentives/Higher/Higher";
import {
  BUDGET_ICON_LIGHT_BLUE,
  RETIREMENT_ICON_LIGHT_BLUE,
  CREDITS_AND_DEBTS_ICON_LIGHT_BLUE,
  SAVINGS_AND_INVESTMENTS_ICON_LIGHT_BLUE,
} from "images/AWS/rokipedia/index";

const RokipediaToolsMenu = () => {
  const classes = useStyles();

  const BudgetCalculatorButtonHandler = () => {
    navigate(Route.toolsCalculatorSteps);
  };
  const ChooseAPVButtonHandler = () => {
    {
      ("navigate(Route.toolsCalculatorSteps);");
    }
  };
  const BasicCreditSimulatorButtonHandler = () => {
    navigate(Route.toolsCreditsAndDebtsMission1);
  };
  const CreditTranslatorButtonHandler = () => {
    navigate(Route.toolsCreditTrasnlator);
  };
  const CreditRefinanceButtonHandler = () => {
    navigate(Route.toolsRefinanceCredit);
  };
  const OrganizerButtonHandler = () => {
    {
      ("navigate(Route.toolsCalculatorSteps);");
    }
  };
  const TimeframeDepositSimulatorButtonHandler = () => {
    navigate(Route.toolsDeposit);
  };
  const invertionProfileTestButtonHandler = () => {
    navigate(Route.toolsSavingAndInvestMission1);
  };

  const isMobile = useMediaQuery("(max-width: 1280px)");

  const [openBudget, setOpenBudget] = useState(true);
  const [openSavingAndInvestments, setOpenSavingAndInvestments] =
    useState(true);
  const [openCreditsAndDebts, setOpenCreditsAndDebts] = useState(true);
  const [openRetirement, setOpenRetirement] = useState(true);

  return (
    <>
      {!isMobile && (
        <div className={classes.mainContainer}>
          <div className={classes.budgetAndRetirement}>
            <div className={classes.cardSmall}>
              <h4 className={classes.cardTitle}>PRESUPUESTO</h4>
              <button
                className={classes.toolButton}
                onClick={BudgetCalculatorButtonHandler}
              >
                <div className={classes.innerCard}>
                  <img src={BUDGET_ICON_LIGHT_BLUE} alt="" />
                  <h4 className={classes.innerCardTitle}>
                    Calculadora de presupuesto
                  </h4>
                </div>
              </button>
            </div>
            <div className={classes.cardSmall}>
              <h4 className={classes.cardTitle}>JUBILACIÓN</h4>
              <button
                className={classes.toolButton}
                onClick={ChooseAPVButtonHandler}
              >
                <div className={classes.innerCardDisabled}>
                  <img src={RETIREMENT_ICON_LIGHT_BLUE} alt="" />
                  <h4 className={classes.innerCardTitle}>Cómo elegir tu APV</h4>
                </div>
              </button>
            </div>
          </div>

          <div className={classes.card}>
            <h4 className={classes.cardTitle}>CRÉDITOS Y DEUDAS</h4>
            <button
              className={classes.toolButton}
              onClick={BasicCreditSimulatorButtonHandler}
            >
              <div className={classes.innerCard}>
                <img src={CREDITS_AND_DEBTS_ICON_LIGHT_BLUE} alt="" />
                <h4 className={classes.innerCardTitle}>
                  Simulador básico de crédito
                </h4>
              </div>
            </button>

            <button
              className={classes.toolButton}
              onClick={CreditTranslatorButtonHandler}
            >
              <div className={classes.innerCard}>
                <img src={CREDITS_AND_DEBTS_ICON_LIGHT_BLUE} alt="" />
                <h4 className={classes.innerCardTitle}>Traductor de crédito</h4>
              </div>
            </button>

            <button
              className={classes.toolButton}
              onClick={CreditRefinanceButtonHandler}
            >
              <div className={classes.innerCard}>
                <img src={CREDITS_AND_DEBTS_ICON_LIGHT_BLUE} alt="" />
                <h4 className={classes.innerCardTitle}>
                  ¿Debería refinanciar mi crédito?
                </h4>
              </div>
            </button>
          </div>

          <div className={classes.card}>
            <h4 className={classes.cardTitle}>AHORRO E INVERSIÓN</h4>

            <button
              className={classes.toolButton}
              onClick={OrganizerButtonHandler}
            >
              <div className={classes.innerCardDisabled}>
                <img src={SAVINGS_AND_INVESTMENTS_ICON_LIGHT_BLUE} alt="" />
                <h4 className={classes.innerCardTitle}>Planificador</h4>
              </div>
            </button>

            <button
              className={classes.toolButton}
              onClick={TimeframeDepositSimulatorButtonHandler}
            >
              <div className={classes.innerCard}>
                <img src={SAVINGS_AND_INVESTMENTS_ICON_LIGHT_BLUE} alt="" />
                <h4 className={classes.innerCardTitle}>
                  Simulador de depósito a plazo
                </h4>
              </div>
            </button>

            <button
              className={classes.toolButton}
              onClick={invertionProfileTestButtonHandler}
            >
              <div className={classes.innerCard}>
                <img src={SAVINGS_AND_INVESTMENTS_ICON_LIGHT_BLUE} alt="" />
                <h4 className={classes.innerCardTitle}>
                  Test Perfil de Inversión
                </h4>
              </div>
            </button>
          </div>
        </div>
      )}
      {isMobile && (
        <div className={classes.mainContainer}>
          <h1 className={classes.financialToolsTitle}>
            Herramientas financieras
          </h1>
          <div className={classes.cardTitleButtonContainer}>
            <h4 className={classes.cardTitle}>PRESUPUESTO</h4>
            <button
              className={
                openBudget
                  ? classes.collapsableButton
                  : classes.collapsableButtonClosed
              }
              onClick={() => setOpenBudget(!openBudget)}
            >
              <KeyboardArrowRightRounded htmlColor="#gray" />
            </button>
          </div>
          <div
            className={openBudget ? classes.showDisplay : classes.hiddenDisplay}
          >
            <button
              className={classes.toolButton}
              onClick={BudgetCalculatorButtonHandler}
            >
              <div className={classes.innerCard}>
                <img src={BUDGET_ICON_LIGHT_BLUE} alt="" />
                <h4 className={classes.innerCardTitle}>
                  Calculadora de presupuesto
                </h4>
              </div>
            </button>
          </div>

          <div className={classes.cardTitleButtonContainer}>
            <h4 className={classes.cardTitle}>AHORRO E INVERSIÓN</h4>
            <button
              className={
                openSavingAndInvestments
                  ? classes.collapsableButton
                  : classes.collapsableButtonClosed
              }
              onClick={() =>
                setOpenSavingAndInvestments(!openSavingAndInvestments)
              }
            >
              <KeyboardArrowRightRounded htmlColor="#gray" />
            </button>
          </div>

          <div
            className={
              openSavingAndInvestments
                ? classes.showDisplay
                : classes.hiddenDisplay
            }
          >
            <button
              className={classes.toolButton}
              onClick={OrganizerButtonHandler}
            >
              <div className={classes.innerCardDisabled}>
                <img src={SAVINGS_AND_INVESTMENTS_ICON_LIGHT_BLUE} alt="" />
                <h4 className={classes.innerCardTitle}>Planificador</h4>
              </div>
            </button>

            <button
              className={classes.toolButton}
              onClick={TimeframeDepositSimulatorButtonHandler}
            >
              <div className={classes.innerCardDisabled}>
                <img src={SAVINGS_AND_INVESTMENTS_ICON_LIGHT_BLUE} alt="" />
                <h4 className={classes.innerCardTitle}>
                  Simulador de depósito a plazo
                </h4>
              </div>
            </button>

            <button
              className={classes.toolButton}
              onClick={invertionProfileTestButtonHandler}
            >
              <div className={classes.innerCard}>
                <img src={SAVINGS_AND_INVESTMENTS_ICON_LIGHT_BLUE} alt="" />
                <h4 className={classes.innerCardTitle}>
                  Test Perfil de Inversión
                </h4>
              </div>
            </button>
          </div>

          <div className={classes.cardTitleButtonContainer}>
            <h4 className={classes.cardTitle}>CRÉDITOS Y DEUDAS</h4>
            <button
              className={
                openCreditsAndDebts
                  ? classes.collapsableButton
                  : classes.collapsableButtonClosed
              }
              onClick={() => setOpenCreditsAndDebts(!openCreditsAndDebts)}
            >
              <KeyboardArrowRightRounded htmlColor="#gray" />
            </button>
          </div>

          <div
            className={
              openCreditsAndDebts ? classes.showDisplay : classes.hiddenDisplay
            }
          >
            <button
              className={classes.toolButton}
              onClick={BasicCreditSimulatorButtonHandler}
            >
              <div className={classes.innerCard}>
                <img src={CREDITS_AND_DEBTS_ICON_LIGHT_BLUE} alt="" />
                <h4 className={classes.innerCardTitle}>
                  Simulador básico de crédito
                </h4>
              </div>
            </button>

            <button
              className={classes.toolButton}
              onClick={CreditTranslatorButtonHandler}
            >
              <div className={classes.innerCard}>
                <img src={CREDITS_AND_DEBTS_ICON_LIGHT_BLUE} alt="" />
                <h4 className={classes.innerCardTitle}>Traductor de crédito</h4>
              </div>
            </button>

            <button
              className={classes.toolButton}
              onClick={CreditRefinanceButtonHandler}
            >
              <div className={classes.innerCard}>
                <img src={CREDITS_AND_DEBTS_ICON_LIGHT_BLUE} alt="" />
                <h4 className={classes.innerCardTitle}>
                  ¿Debería refinanciar mi crédito?
                </h4>
              </div>
            </button>
          </div>

          <div className={classes.cardTitleButtonContainer}>
            <h4 className={classes.cardTitle}>JUBILACIÓN</h4>
            <button
              className={
                openRetirement
                  ? classes.collapsableButton
                  : classes.collapsableButtonClosed
              }
              onClick={() => setOpenRetirement(!openRetirement)}
            >
              <KeyboardArrowRightRounded htmlColor="#gray" />
            </button>
          </div>

          <div
            className={
              openRetirement ? classes.showDisplay : classes.hiddenDisplay
            }
          >
            <button
              className={classes.toolButton}
              onClick={ChooseAPVButtonHandler}
            >
              <div className={classes.innerCardDisabled}>
                <img src={RETIREMENT_ICON_LIGHT_BLUE} alt="" />
                <h4 className={classes.innerCardTitle}>Cómo elegir tu APV</h4>
              </div>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default RokipediaToolsMenu;
