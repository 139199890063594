import React, { useState, useEffect, useContext, useCallback } from "react";
import { PageProps } from "gatsby";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { LayoutOnBoarding, SEO } from "@components";
import RokipediaLayout from "@components/Rokipedia/RokipediaLayout";
import { AppContext, ProfileContext, LoadingContext } from "@context";
import { getUserMissions, useCurrentUser } from "@apollo";
import { useAuth } from "@hooks";
import { LayoutMobileInvestments } from "@components/Investments/Layout";
import useHotjar from "react-use-hotjar";

const incentives: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const isMobile = useMediaQuery("(min-width: 1270px)");
  const classes = useStyles();

  const { profileForm } = useContext(ProfileContext);
  const [isCompany, setIsCompany] = useState<boolean>();
  const { openAddCompanyModal } = useContext(AppContext);
  const { userMissions } = getUserMissions();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [profileName, setProfileName] = useState("Usuario");

  const { identifyHotjar } = useHotjar();
  const myCustomLogger = console.log;
  const { user: currentUser } = useCurrentUser();

  const logininfo = {
    name: currentUser?.name,
    identification: process.env.HOTJAR_ID || "0",
  };

  const missionsByCategory = useCallback(() => {
    const missions = Object.values(userMissions).sort((a, b) => {
      return a.mission.id - b.mission.id;
    });
    return Object.entries(
      missions.reduce((acc, cur) => {
        acc[cur.mission.category] = [...(acc[cur.mission.category] || []), cur];
        return acc;
      }, [])
    );
  }, [userMissions]);

  useEffect(() => {
    setProfileName(profileForm.name);
    if (!profileForm.name) {
      showLoader("");
    } else {
      hideLoader("");
      if (!profileForm.company) {
        setIsCompany(false);
      } else {
        setIsCompany(false);
      }
    }
    hideLoader("");
  }, [currentUser]);

  const handleAddCompany = () => {
    openAddCompanyModal();
  };

  return (
    <>
      <SEO
        title="Conoce el mundo financiero - Rokin"
        description="Juega para completar misiones y ganar roks, mientras descubres herramientas para mejorar tu educación financiera y manejar tu plata."
      />
      {/* {isCompany === false && (
        <div className={classes.astronaut}>
          <div>
            {!isMobile && <img src={astronautMagnifyingGlass} />}
            <div>¡Ups, no estas vinculado a una empresa!</div>
            <div className={classes.text2}>
              Si tienes una empresa vincúlate a ella.
            </div>
            <div></div>
            {isMobile && <img src={astronautMagnifyingGlass} />}
          </div>
          <div>
            <ButtonPrimary
              startIcon={<AddIcon />}
              onClick={handleAddCompany}
              secondaryVersion
              text="Vincularme a una empresa"
            />
          </div>
        </div>
      )} */}
      {isMobile ? (
        <LayoutOnBoarding activeRokinpedia>
          <RokipediaLayout />
        </LayoutOnBoarding>
      ) : (
        <LayoutMobileInvestments activeRokipedia>
          <RokipediaLayout />
        </LayoutMobileInvestments>
      )}
    </>
  );
};

export default incentives;

const useStyles = makeStyles((theme) => ({
  background: {
    background: theme.palette.primary.dark,
  },
  container: {},
  astronaut: {
    position: "fixed",
    zIndex: 20,
    display: "flex",
    background: "rgba(0, 0, 0, 0.8)",
    color: "white",
    fontSize: 36,
    alignItems: "center",
    fontWeight: "bold",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    overflowY: "hidden",
    "& >section": {},
    [theme.breakpoints.down(1280)]: {
      alignItems: "center",
      fontSize: 24,
      textAlign: "center",
      top: 0,
      left: 0,
      display: "grid",
      gridTemplateRows: "50% 7%",
    },
    "&> div": {
      paddingBottom: "11rem",
      marginTop: 200,
      [theme.breakpoints.down(1280)]: {
        paddingBottom: "1rem",
        width: 270,
        marginTop: 0,
      },
    },
    "& img": {
      width: 300,
      [theme.breakpoints.down(1280)]: {
        width: 140,
        height: 140,
        margin: "0 auto",
      },
    },
  },
  text2: {
    marginBotton: 34,
  },
}));
