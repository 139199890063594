import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./Higher.style";
import { actionIcon, Level_1, moneyIcon } from "images/Incentives";
import Progressbar from "react-js-progressbar";
import CardIncentives from "../CardIncentives/CardIncentives";
import { navigate } from "@reach/router";
import { Route } from "@interfaces";
import { getMissionTime } from "@utils";
import { MissionsInfo } from "@data";
import { useCurrentUser } from "@apollo";
import { ROKS_ICON_PINK } from "images/GCP";
import {
  budgetIlustration,
  creditAndDebt,
  insuranceIlustration,
  profileIlustration,
  roks,
  saving,
} from "@images";
import { LevelsInfo } from "data/levels";

interface HigherProps {
  missionsByCategory: any;
}
const Higher: React.FC = ({ missionsByCategory }: HigherProps) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery("(min-width: 1279px)");
  const value = 10000;
  const valueRequerid = 100000;
  const progress = (value * 100) / valueRequerid;
  const { user: currentUser } = useCurrentUser();

  const handleCardClick = (category) => {
    const categorySelected = Object.values(MissionsInfo).filter(
      (obj) => obj.name == category
    )[0];
    navigate(categorySelected?.categoryRoute);
  };

  const handleImage = (category) => {
    const categorySelected = Object.values(MissionsInfo).filter(
      (obj) => obj.name == category
    )[0];
    return categorySelected?.smallImage;
  };
  const levelInfo = Object.values(LevelsInfo).filter(
    (obj) => obj.id == currentUser?.level
  )[0];
  return (
    <>
      <section className={classes.container}>
        <section className={classes.cardLevel}>
          <div className={classes.progressBar}>
            <Progressbar
              input={progress}
              pathWidth={0}
              pathColor={["grey", "#8250ff"]} // use an array for gradient color.
              trailWidth={20}
              trailColor="rgba(255, 255, 255, 0.5)" // use a string for solid color.
              textStyle={{ fill: "#393e44" }} // middle text style
            />
            <img className="img" src={levelInfo?.image} />
            <img className={classes.roks} src={roks} />
          </div>

          <span className={classes.dataLevel}>
            <p>Nivel {currentUser?.level}</p>
            <span>{levelInfo?.name}</span>
            <div className={classes.dataRow}>
              <img src={ROKS_ICON_PINK} alt="" />
              <span>{currentUser?.roks} Roks</span>
            </div>
          </span>
        </section>
        <div className={classes.blockLila}></div>
        <div className={classes.blockPurple}>
          <div className={classes.rokinpedia}>
            <div>
              <span className="title">MISIONES</span>
              <span className="subtitle">
                Haz nuestra ruta de misiones e infórmate sobre lo que quieras y
                necesites
              </span>
            </div>
            {/**<div className="search">
              <TextInput
                label="Sobre qué queres saber..."
                onChange={() => { }}
              />
              <img src={searchButton} />
            </div>**/}
          </div>
          <div className={classes.cards}>
            {isDesktop &&
              missionsByCategory.map((card) => {
                const thisTitle = Object.entries(MissionsInfo).find(
                  (obj) => obj[1].name === card[0]
                )[1];
                return (
                  <>
                    {card[0] ? (
                      <CardIncentives
                        key={card[0]}
                        missions={card[1]}
                        image={handleImage(card[0])}
                        title={thisTitle.title}
                        onClick={() => handleCardClick(card[0])}
                        time={getMissionTime(card[1])}
                        isProgressBar={true}
                        className={classes.classIncentive}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
          </div>

          {!isDesktop && (
            <>
              <div className={classes.simpleSlider2}>
                {missionsByCategory.map((card) => {
                  const thisTitle = Object.entries(MissionsInfo).find(
                    (obj) => obj[1].name === card[0]
                  )[1];
                  return (
                    <>
                      {card[0] ? (
                        <section
                          key={card[0]}
                          className={classes.containerCard}
                        >
                          <CardIncentives
                            missions={card[1]}
                            image={handleImage(card[0])}
                            title={thisTitle.title}
                            onClick={() => handleCardClick(card[0])}
                            time={getMissionTime(card[1])}
                            isProgressBar={true}
                          />
                        </section>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </div>
            </>
          )}

          {/*{!isDesktop && (
            <div className={classes.simpleSlider2}>
              {missionsByCategory.map((card) => (
                <section key={card[0]} className={classes.containerCard}>
                  <CardIncentives
                    missions={card[1]}
                    image={handleImage(card[0])}
                    title={card[0]}
                    onClick={() => handleCardClick(card[0])}
                    time={getMissionTime(card[1])}
                    isProgressBar={true}
                  />
                </section>
              ))}
            </div>
          )}
          
          <div className={classes.sliderContainer}>
            <section>
              <div className={classes.sliderTop}>
                <span className="title">Desafios rokin</span>
                <span className="subtitle">
                  Completa estas misiones y gana Roks extra!
                </span>
              </div>
              <div>
                <button
                  onClick={() => slider?.current?.slickPrev()}
                  style={arrowLeft}
                />
                <div className={classes.slider}>
                  {isMobile ? (
                    <Slider ref={slider} {...settings}>
                      <div className={classes.chall}>
                        <CardChallenge
                          key={challenge[0].image}
                          time={challenge[0].time}
                          title={challenge[0].title}
                          roks={challenge[0].roks}
                          image={challenge[0].image}
                          enabled={challenge[0].enabled}
                        />{" "}
                        <CardChallenge
                          key={challenge[1].image}
                          time={challenge[1].time}
                          title={challenge[1].title}
                          roks={challenge[1].roks}
                          image={challenge[1].image}
                          enabled={challenge[1].enabled}
                        />
                        <CardChallenge
                          key={challenge[2].image}
                          time={challenge[2].time}
                          title={challenge[2].title}
                          roks={challenge[2].roks}
                          image={challenge[2].image}
                          enabled={challenge[2].enabled}
                        />{" "}
                      </div>
                      <div>
                        <CardChallenge
                          key={challenge[3].image}
                          time={challenge[3].time}
                          title={challenge[3].title}
                          roks={challenge[3].roks}
                          image={challenge[3].image}
                          enabled={challenge[3].enabled}
                        />
                      </div>
                    </Slider>
                  ) : (
                    <Slider ref={slider} {...settings}>
                      <div className={classes.chall}>
                        <CardChallenge
                          key={challenge[0].image}
                          time={challenge[0].time}
                          title={challenge[0].title}
                          roks={challenge[0].roks}
                          image={challenge[0].image}
                          enabled={challenge[0].enabled}
                        />{" "}
                        <CardChallenge
                          key={challenge[1].image}
                          time={challenge[1].time}
                          title={challenge[1].title}
                          roks={challenge[1].roks}
                          image={challenge[1].image}
                          enabled={challenge[1].enabled}
                        />
                        <CardChallenge
                          key={challenge[2].image}
                          time={challenge[2].time}
                          title={challenge[2].title}
                          roks={challenge[2].roks}
                          image={challenge[2].image}
                          enabled={challenge[2].enabled}
                        />
                        <CardChallenge
                          key={challenge[3].image}
                          time={challenge[3].time}
                          title={challenge[3].title}
                          roks={challenge[3].roks}
                          image={challenge[3].image}
                          enabled={challenge[3].enabled}
                        />
                      </div>
                    </Slider>
                  )}
                </div>
                <button
                  onClick={() => slider?.current?.slickNext()}
                  style={arrowRight}
                />
              </div>
            </section>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Higher;

export const cards = [
  {
    image: profileIlustration,
    title: "Completa tu perfil",
    time: "8 minutos",
    load: 0,
    onClick: () => navigate(Route.completeProfile),
  },
  {
    image: budgetIlustration,
    title: "Presupuesto",
    time: "8 minutos",
    load: 0,
    onClick: () => navigate(Route.budget),
  },
  {
    image: saving,
    title: "Ahorro e Inversión",
    time: "8 minutos",
    load: 0,
    onClick: () => navigate(Route.savingsAndInvestment),
  },
  {
    image: creditAndDebt,
    title: "Créditos y deudas",
    time: "8 minutos",
    load: 0,
    onClick: () => navigate(Route.creditsDebts),
  },
  {
    image: insuranceIlustration,
    title: "Jubilación",
    time: "8 minutos",
    load: 0,
    onClick: () => navigate(Route.retirement),
  },
];

const challenge = [
  {
    image: actionIcon,
    title: "Crea un objetivo",
    time: "3 minutos",
    roks: 10,
    enabled: true,
  },
  {
    image: actionIcon,
    title: "Elige tu Perfil de Inversión",
    time: "3 minutos",
    roks: 15,
    enabled: true,
  },
  {
    image: moneyIcon,
    title: "Agrega un presupuesto",
    time: "3 minutos",
    roks: 5,
    enabled: false,
  },
  {
    image: moneyIcon,
    title: "Sincroniza una cuenta",
    time: "3 minutos",
    roks: 5,
    enabled: false,
  },
];

const settings = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 2,
  slidesToScroll: 2.5,
  pauseOnHover: false,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
};
