import React, { useState, useEffect } from "react";
import { useStyles } from "./CardIncentives.style";
import { ProgressBarLine } from "react-progressbar-line";

interface CardIncentivesProps {
  image: string;
  title: string;
  load?: number;
  onClick: () => void;
  finance?: boolean;
  className?: string;
  missions: any;
  time: string;
  isProgressBard: boolean;
}

const CardIncentives = ({
  image,
  title,
  load,
  onClick,
  finance,
  className,
  missions,
  time,
  isProgressBar,
}: CardIncentivesProps) => {
  const classes = useStyles({ load, financ: finance });

  return (
    <section onClick={onClick} className={`${classes.container} ${className}`}>
      <div className={`${classes.img} img`}>
        <img src={image} />
      </div>
      <div className="line">
        <span className="title">{title}</span>
        {isProgressBar && (
          <ProgressBarLine
            value={missions?.filter((obj) => obj.state == "COMPLETED").length}
            min={0}
            max={missions?.length}
            text=" "
            styles={{
              path: {
                stroke: "#0a5669",
              },
            }}
          />
        )}
      </div>
    </section>
  );
};

export default CardIncentives;
