import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.text.primary,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    minHeight: 968,
    position: "relative",
    [theme.breakpoints.down(1280)]: {
      display: "block",
      width: "100%",
      justifyContent: "center",
      background: "rgba(0, 0, 0, 0)",
      margin: "-2rem 0 0 0",
      borderRadius: "35px 35px 0 0",
      minHeight: 0,
      height: "auto",
      padding: "0 0 4rem 0",
    },
  },
  cardLevel: {
    background: "#210565",
    width: 325,
    height: 430,
    borderRadius: 30,
    padding: "30px 32px",
    zIndex: 2,
    margin: "0rem 3.125rem 0 0",
    [theme.breakpoints.down(1279)]: {
      width: "100%",
      background: "rgba(244, 243, 243, 0)",
      display: "flex",
      flexDirection: "row",
      height: 240,
      justifyContent: "center",
    },
  },
  welcome: {
    fontFamily: "Montserrat",
    fontSize: 24,
    fontWeight: "bold",
    color: "#ffffff",
  },
  progressBar: {
    width: 200,
    margin: "2rem auto",
    position: "relative",
    [theme.breakpoints.down(1279)]: {
      margin: "-2rem 0 0 0",
    },

    "& text": {
      display: "none",
    },
    "&>svg": {
      transform: "rotate(180deg)",
      maxWidth: 200,
    },
    "& img": {
      position: "absolute",
      top: 13,
      left: -4,
      width: 191,
      [theme.breakpoints.down(1279)]: {
        top: 18,
      },
    },
  },
  dataLevel: {
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Montserrat",
    display: "block",
    textAlign: "center",
    marginTop: "1rem",
    "& p": {
      color: "#ffffff",
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    "& span": {
      display: "block",
      fontWeight: "normal",
      fontSize: "0.875rem",
      color: "#ffffff",
    },
  },
  blockLila: {
    minWidth: 1300,
    height: 760,
    borderRadius: 30,
    position: "absolute",
    top: "5rem",
    right: "2rem",
    zIndex: 0,

    [theme.breakpoints.down(1370)]: {
      minWidth: 1200,
    },
    [theme.breakpoints.down(1279)]: {
      display: "none",
    },
    [theme.breakpoints.up(1900)]: {
      minWidth: "70%",
    },
  },
  blockPurple: {
    background: "#210565",
    width: 900,
    height: 570,
    zIndex: 2,
    marginTop: "0rem",
    borderRadius: 22,
    position: "relative",
    [theme.breakpoints.down(1370)]: {
      width: "60%",
    },
    [theme.breakpoints.down(1279)]: {
      margin: "0 auto",
      background: "#210565",
      width: "100%",
      padding: "0rem 1rem 0 1rem",
      height: "auto",
    },
  },
  rokinpedia: {
    background: "#F4F3F3",
    borderRadius: 30,
    height: 177,
    width: "90%",
    margin: "0 0 0 3rem",
    marginTop: "3rem",
    padding: "23px 54px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down(1279)]: {
      display: "flex",

      width: "100%",
      margin: 0,
      padding: "0 0 0 2rem",
      alignItems: "center",
      height: 150,
    },
    "& .search": {
      display: "flex",
      alignItems: "center",
      "&>div": {
        width: "90%",
      },
      "& > div div": {
        borderRadius: 20,
      },
    },
    "& div": {
      fontFamily: "Montserrat",

      "& span": {
        display: "block",
      },
      "& .title": {
        fontWeight: 500,
        fontSize: 24,
        [theme.breakpoints.down(560)]: {
          fontSize: 20,
        },
      },
      "& .subtitle": {
        fontSize: 16,
        fontWeight: 300,
        color: theme.palette.text.secondary,
        width: "100%",
        margin: 0,
        [theme.breakpoints.down(560)]: {
          fontSize: 12,
        },
      },
    },
    "& >div": {
      width: "90%",
      margin: "0",
    },
  },
  cards: {
    position: "absolute",
    top: 180,
    left: 80,
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down(1370)]: {
      left: 45,
    },
    "& section": {
      margin: "0 1.2rem 2rem",
    },
  },
  sliderContainer: {
    background: "#F4F3F3",
    padding: "25px 20px",
    width: 900,
    height: 415,
    bottom: -450,
    position: "relative",
    borderRadius: 30,
    display: "flex",
    [theme.breakpoints.down(1370)]: {
      width: 850,
    },
    [theme.breakpoints.down(1279)]: {
      bottom: 0,
      marginTop: "3rem",
      height: 600,
      justifyContent: "center",
      width: 400,
    },
    "& section >div:last-child": {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(1279)]: {
        display: "grid",
        "& button": {
          margin: "0 auto!important",
          transform: "rotate(90deg)",
        },
        "& button:last-child": {
          margin: "30px auto 0!important",
          transform: "rotate(90deg)",
        },
      },
    },
  },
  chall: {
    display: "flex!important",
    width: 900,
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.down(1300)]: {
      width: 780,
    },
    [theme.breakpoints.down(1279)]: {
      width: 400,
    },
  },
  slider: {
    width: 760,
    padding: "0 20px",
    [theme.breakpoints.down(1279)]: {
      width: 400,
    },
  },
  sliderTop: {
    paddingLeft: "4.5rem",
    [theme.breakpoints.down(1279)]: {
      paddingLeft: "3rem",
    },
    "& span": {
      display: "block",
    },
    "& .title": {
      fontWeight: "bold",
      fontSize: 24,
      fontFamily: "Montserrat",
    },
    "& .subtitle": {
      fontWeight: 300,
      fontSize: 16,
      color: theme.palette.text.primary,
      marginBottom: "2rem",
    },
  },
  containerCard: {
    width: "50%",
    zIndex: 3,
    marginTop: "1.5rem",
  },
  simpleSlider: {
    marginTop: "2rem",
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100%",
    justifyContent: "space-between",
    fontFamily: "Montserrat",
    zIndex: 3,
    gap: "5rem",
    overflow: "auto",
    padding: "0 0 2rem",
    [theme.breakpoints.down(560)]: {
      gap: "1rem",
    },
    "& >div >div >div >div section": {
      boxShadow: "none",
      margin: "0 auto",
      height: "auto",
    },
    "& .img": {
      height: "2.495rem!important",
      width: "2.875rem!important",
    },
    [theme.breakpoints.down(1279)]: {
      width: "100%",
    },
  },
  simpleSlider2: {
    marginTop: "-2.5rem",
    marginLeft: "3%",
    margin: "0 auto",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-between",
    fontFamily: "Montserrat",
    zIndex: 3,
    padding: "0 0 2rem",
    [theme.breakpoints.down(560)]: {},
    "& >div >div >div >div section": {
      boxShadow: "none",
      margin: "0 auto",
      height: "auto",
    },
    "& .img": {
      height: "2.495rem!important",
      width: "2.875rem!important",
    },
    [theme.breakpoints.down(1279)]: {
      width: "100%",
    },
  },
  astronaut: {
    width: "80%",
    position: "absolute",
    top: 100,
    left: 100,
    zIndex: 5,
    display: "flex",
    margin: " 0 auto",
    color: theme.palette.text.primary,
    fontSize: 36,
    alignItems: "center",
    fontWeight: "bold",
    justifyContent: "center",
    [theme.breakpoints.down(1279)]: {
      fontSize: 24,
      color: "white",
      top: 20,
    },
    "&> div": {
      paddingBottom: "8rem",
      [theme.breakpoints.down(1279)]: {
        paddingBottom: "1rem",
        width: 257,
      },
    },
    "& img": {
      width: 300,
      [theme.breakpoints.down(1279)]: {
        width: 100,
      },
    },
  },
  classIncentive: {
    width: "9.63rem!important",
    height: "7.5rem",
    "& .img": {
      height: "2.495rem!important",
      width: "2.875rem!important",
    },
    "& .time": {
      marginTop: "0.5rem!important",
    },
    "& img": {
      height: "50px!important",
    },
  },
  roks: {
    position: "absolute",
    zIndex: 5,
    width: "4rem!important",
    top: "150px!important",
    [theme.breakpoints.down(1279)]: {
      top: "165px!important",
    },
  },
  dataRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    "& span": {
      marginLeft: "0.5rem",
    },
  },
  sidebarButton: {
    width: "3.938rem",
    height: "4.563rem",
    backgroundColor: "#393e44",
    borderRadius: "15px",
    opacity: 0.25,
    display: "flex",
    position: "absolute",
    zIndex: 1000,
    top: 0,
    left: 0,
  },
}));
